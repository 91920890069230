
























export default {
  methods: {
    goHome(): void {
      this.$router.push({ path: '/' });
    }
  }
};
